/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Rational W05 Bold";
  src: url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5210135/379c680c-96b3-4c2b-9edf-00b8c780a72c.woff2")
      format("woff2"),
    url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5210135/3dd84230-c514-4d6b-bcf5-50323dfb4aec.woff")
      format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Rational W05 SemiBold";
  src: url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5222225/2a2f3730-da48-416f-9a54-8fb66e7cb411.woff2")
      format("woff2"),
    url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5222225/d97ac348-a70a-4206-a311-2446ce94eca4.woff")
      format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Rational W05 Medium";
  src: url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5213434/2028415c-9828-4ec7-bd8e-2de14e948670.woff2")
      format("woff2"),
    url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5213434/7cbbc64a-899f-49ce-baed-3d0afd6dde2d.woff")
      format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Rational W05 Book";
  src: url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5219219/c4d0198c-09c7-4472-901b-b7c96df5ef38.woff2")
      format("woff2"),
    url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5219219/ec787ef9-5d16-4221-bbec-fb0615087449.woff")
      format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Rational W05 Light";
  src: url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5226239/7040ba13-3cf1-4e1a-94dd-28710324d28c.woff2")
      format("woff2"),
    url("https://lfg-fonts.s3.us-west-2.amazonaws.com/Fonts/5226239/3de68231-6ee8-445e-ba1f-25650955f358.woff")
      format("woff");
  font-display: swap;
}

body {
  font-family: "Rational W05 Book";
}

.h-screen-with-header {
  height: calc(100vh - 10em);
}
